import Sign from '../pages/Sign';
import Home from '../pages/Home/index';

const routes = [
    {
        path: ":signing_document/:access_code",
        component: Sign,
    },
    {
        path: "",
        component: Home,
    },
]

export default routes;