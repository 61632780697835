import { Container } from 'reactstrap';

const FailedConnection = () => (
    <Container>
        <div className="text-center py-6">
            <h2 className="text-uppercase mb-4">
                An unexpected error occurred. <br />
                <span className="text-underline">We are working on the issue and will have it fixed shortly.</span>
            </h2>

            <div className="text-sm bg-white p-4 rounded">
                <h3 className="text-danger">Database Failed To Connect.</h3>
            </div>

        </div>
    </Container>
)

export default FailedConnection;
