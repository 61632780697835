import { useEffect, useState } from "react";

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import { Container } from 'reactstrap';
import renderUpload from '../_functions/renderUpload';

import Dots from 'components/markup/loading/Dots'

import Progress from './Progress'

const SignSignature = ({signingDocument, pdfData, onSign}) => {

    const [loaded, setLoaded] = useState(false);
    const [renderedPages, setRenderedPages] = useState(<div />);

    useEffect(() => {
        document.body.classList.add('noScroll')

        let _images = []
        let _totalPages = 0

        const onImageLoaded = (img, page) => {
            _images[page] = img;
        }
        const onPagesFound = (pages) => {
            _totalPages = pages;
        }

        toggleStandardLoader(true)

        renderUpload(pdfData, onImageLoaded, onPagesFound, () => {
            setLoaded(true)
            toggleStandardLoader(false)
            document.body.classList.remove('noScroll')

            toggleAlertBS('Almost There', `Please review this document to make sure it's correct and click the green "Adopt & Sign" button in the bottom right hand corner of your screen to finish signing this document.`)

            const markup = [];

            _images.forEach((image, i) => {
                markup.push((
                    <div key={i}>
                        <p className="text-sm text-right mb-0">Page {i + 1} / {_totalPages}</p>
                        <div id={`pdf-canvas-page-${i + 1}-wrapper`} data-pdf-page-wrapper="true" style={{position: 'relative', width: '100%'}}>
                            <div className="page-overlay" />
                            <img id={`pdf-canvas-page-${i + 1}`} className="z-depth-3 mb-3 rounded" data-page={i + 1} src={image} alt={`page ${i + 1}`} style={{width: '100%'}} />
                        </div>
                    </div>
                ))
            })
            setRenderedPages(markup);
            window.scrollTo(0,0)

        })

    }, [pdfData, signingDocument.company.name])

    return (

        <>
            
            <Container className="archk-template-docs-container pb-6">
                <div className="archk-template-docs"  >

                    <div className="archk-template-docs-main">
                        <div style={{height: 'auto',  maxWidth: '100%',  margin: 'auto'}} >

                            <div id="canvas-wrapper" className="position-relative" style={{opacity: loaded ? 1 : 0}}>
                                <div id="pdf-canvas" className="position-relative">
                                    {loaded ? renderedPages : <>Loading Your Document Preview <Dots /></>}
                                </div>
                            </div>

                        </div>
                    </div>

                    <Progress 
                        loaded={loaded} 
                        onSign={onSign} 
                        signingDocument={signingDocument} 
                    />

                </div>
            </Container>

            <div id="canvas-anchor" style={{display: 'none'}} />

        </>

    );
}

export default SignSignature
