import { useEffect, useCallback, useState } from "react";
import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import _signing_documents from '_functions/signing_documents'
import _signing_events from '_functions/signing_events'

import Circle from 'components/markup/loading/Circle'

import Signature from './Signature'

import Preview from './Preview'
import Download from './Download'
import Header from './Header'
import Error from './Error'
import IsSigned from './IsSigned'
import Void from './Void'
import NewLinkSent from './NewLinkSent'

import Password from './Password'

import Axios from 'axios';
import keys from 'keys';

import deviceInfo from 'utils/deviceInfo'

const Sign = ({match}) => {

    const [baseRequest] = useState({
        responseType:'arraybuffer',
        headers: { authorization: `Bearer ${keys.SYSTEM_API_KEY}` },
        withCredentials: true,
    });

    const [err, setErr] = useState(null);
    const [showNewLink, setShowNewLink] = useState(false);
    const [isVoid, setIsVoid] = useState(false);
    const [isSigned, setIsSigned] = useState(false);
    const [previewPDFData, setPreviewPDFData] = useState(null);
    const [signedPDFData, setSignedPDFData] = useState(null);
    const [pdfData, setPDFData] = useState(null);
    const [signingDocument, setSigningDocument] = useState(null);
    const [signState, setSignState] = useState({
        signature : null,
        initials  : null,
        markers   : null,
    });

    const onPasswordSucceeded = useCallback(async (_pdfData) => {
        setPDFData(_pdfData)
    }, []);

    const isApiError = useCallback((result) => {
        if(result.headers && result.headers['content-type'].includes('application/json')) {
            return true;
        }
        return false;
    }, [])

    const decodeError = useCallback((result) => {
        try {
            var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
            var body = JSON.parse(decodedString);
            if(body.message && body.message.length) {
                return body.message[0] ? body.message[0] : `Something's not right, please try again`;
            }
        } catch(e) {}
        return `Something's not right, please try again`;
    }, [])

    const validatePassword = useCallback(async (_signingDocument, password, cb) => {
        try {
            const result = await Axios({
                ...baseRequest,
                method: 'post',
                url: keys.API_URL + `/v1/signing/documents/${_signingDocument._id}/password`,
                data: { 
                    contact: _signingDocument.contactParty.contact, 
                    password 
                },
            })

            if(isApiError(result)) return cb(decodeError(result));
            cb(null, result.data)
            
       } catch(e) {
           console.log(e)
           toggleStandardLoader(false);
           return cb('Please Try Again');
       }
    }, [baseRequest, isApiError, decodeError])

    const onPreview = useCallback(async (data) => {
        try {
            toggleStandardLoader(true);
            const result = await Axios({
                ...baseRequest,
                method: 'post',
                url: keys.API_URL + `/v1/signing/documents/${signingDocument._id}/preview`,
                data: { 
                    contact   : signingDocument.contactParty.contact,
                    signature : data.signature,
                    initials  : data.initials,
                    markers   : data.markers,
                },
            })
            toggleStandardLoader(false);

            if(isApiError(result)) return toggleAlertBS(true, decodeError(result));

            _signing_events.create({
                contact             : signingDocument.contactParty.contact, 
                signing_document    : signingDocument._id,
                event               : 'Finalized Document With Fields And Signature'
            })
            setPreviewPDFData(result.data)
            setSignState({
                signature : data.signature,
                initials  : data.initials,
                markers   : data.markers,
            })
       } catch(e) {
           console.log(e)
           toggleStandardLoader(false);
           return toggleAlertBS(true, `Something's not right, please try again`);
       }
    }, [signingDocument, baseRequest, isApiError, decodeError])

    const onSign = useCallback(async () => {
        try {
            toggleStandardLoader(true);
            const result = await Axios({
                ...baseRequest,
                method: 'post',
                url: keys.API_URL + `/v1/signing/documents/${signingDocument._id}/sign`,
                data: { 
                    contact             : signingDocument.contactParty.contact,
                    document_object_key : signingDocument.document_object_key,
                    signature           : signState.signature,
                    initials            : signState.initials ? signState.initials : undefined,
                    markers             : signState.markers,
                    device              : deviceInfo.init(),
                },
            })
            toggleStandardLoader(false);

            if(isApiError(result)) return toggleAlertBS(true, decodeError(result));

            setSignedPDFData(result.data)

       } catch(e) {
           console.log(e)
           toggleStandardLoader(false);
           return toggleAlertBS(true, `Something's not right, please try again`);
       }

    }, [signingDocument, signState, baseRequest, isApiError, decodeError])

    const fetchDocument = useCallback(async () => {
        const { signing_document, access_code } = match.params

        const templateDocSent = await _signing_documents.findById(signing_document, access_code);

        if(!templateDocSent.data) {
            if(templateDocSent.message.includes('To protect the security of this document a new link was sent to either the phone number or email address on file. Please use that link to access this document.')) {
                return setShowNewLink(true)
            } else {
                return setErr(true)
            }
        }
        const contactParty = templateDocSent.data.contactParty;

        if(templateDocSent.data.status === 'signed' || contactParty.signed_on) return setIsSigned(true);
        if(templateDocSent.data.status === 'void') return setIsVoid(true);

        if(!contactParty.requiresPassword) {
            validatePassword(templateDocSent.data, '', (err, data) => {
                if(err) return toggleAlertBS(true, `This document is unable to be signed at this time.`);
                onPasswordSucceeded(data);
            })
        }

        _signing_events.create({
            contact             : contactParty.contact, 
            signing_document    : signing_document,
            event               : 'Opened Document'
        })

        setSigningDocument(templateDocSent.data)

    }, [match.params, onPasswordSucceeded, validatePassword])

    useEffect(() => {
        fetchDocument();
    }, [fetchDocument])

    if(err) return <Error />
    if(isVoid) return <Void />
    if(isSigned) return <IsSigned />
    if(showNewLink) return <NewLinkSent />

    if(!signingDocument) return <div className="py-6"><Circle /></div>

    if(signingDocument.contactParty.requiresPassword && !pdfData) return (
        <Password
            signingDocument={signingDocument} 
            onPasswordSucceeded={onPasswordSucceeded}
            validatePassword={validatePassword}
        />
    )

    if(signedPDFData) return (
        <Download 
            signingDocument={signingDocument}
            signedPDFData={signedPDFData}
        />
    )

    return (
        <div>
            <Header 
                signingDocument={signingDocument}
            />

            {previewPDFData ? (
                <Preview 
                    signingDocument={signingDocument}
                    allMarkers={signingDocument.markers}
                    pdfData={previewPDFData}
                    onPreview={onPreview}
                    onSign={onSign}
                />
            ) : pdfData ? (
                <Signature 
                    signingDocument={signingDocument}
                    allMarkers={signingDocument.markers}
                    pdfData={pdfData}
                    onPreview={onPreview}
                />
            ) : <div className="py-8"><Circle /></div>}
        </div>
    )

}

export default Sign
