

import { Redirect } from 'react-router-dom';
import { getUrlParameter } from 'utils/urls'
import { Container } from 'reactstrap';
import { log } from 'database';

const Standard = () =>  {

    const error = getUrlParameter('error')
    const referrerurl = getUrlParameter('referrerurl')

    log({
        url: referrerurl,
        file: '/src/views/errors/pages/standard.js',
        code: 'Unexpected Client Error',
        text: error
    });

    if(error) {
        return (
            <Container>
                <div className="text-center py-6">
                    <h2 className="text-uppercase mb-4">
                        An unexpected error occurred. <br />
                        <span className="text-underline">Don't worry, the developers have been notified, please either try the action you were performing again or check back in a few hours.</span>
                    </h2>

                    <div className="text-sm bg-white p-4 rounded">
                        <h3 className="text-danger">{error}</h3>
                        <br />

                        <span className="text-underline">{referrerurl}</span>
                    </div>
                </div>
            </Container>
        )
    }

    return (
        <Redirect to="/" />
    );

}

export default Standard;
