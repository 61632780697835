
import PropTypes from 'prop-types';
import React from "react";
import SignatureCanvas from 'react-signature-canvas';
import { FormGroup, Modal } from "reactstrap";

import _signing_events from '_functions/signing_events'

class SignModalAddSignature extends React.Component {

    state = {
        name: {}
    }

    clearSigPad = () => {
        this.props.setSignature(null, null);
        this.sigPadSignature.clear()
    }

    resizeCanvas = () => {

        const box = document.getElementById('signBox')

        if(box) {
            const width = box.offsetWidth
            const height = width / 3

            this.setState({width, height})

            if(this.sigPadSignature) {

                let signature64 = this.sigPadSignature.getTrimmedCanvas()

                document.querySelector('.sigCanvas').width = width
                document.querySelector('.sigCanvas').height = height
    
                this.sigPadSignature.fromDataURL(signature64.toDataURL('image/png'))
            }

        }

    }

    onConfirm = () => {
        const signature = this.sigPadSignature.getTrimmedCanvas().toDataURL('image/png')

        var image = new Image(); 
        image.src = signature; 

        image.onload = () => {
            if(signature.length > 1000) {
                this.props.setSignature(signature, image.width, image.height);
            }
            _signing_events.create({
                contact             : this.props.signingDocument.contactParty.contact, 
                signing_document    : this.props.signingDocument._id,
                event               : 'Confirmed Signature'
            })
            this.props.toggleModalAddSignature()
        };

    }

    componentDidMount = () => {
        window.addEventListener('resize', this.resizeCanvas)
        this.resizeCanvas();
    }

    render() {

        const { width, height } = this.state;
        const { showModalAddSignature, toggleModalAddSignature, signature } = this.props

        return (

            <Modal
                className="modal-dialog-centered modal-add-signature"
                isOpen={showModalAddSignature}
                toggle={() => {}}
                size="lg"
                fade={false}
                zIndex={999999999999}
            >

                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add Signature</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalAddSignature}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">
                    {signature ? (
                        <p className="text-sm mb-0">Use the box below to add or update your signature for this document. {window.innerWidth < 425 ? <b>Make sure your signature is one one line, if you are on a mobile device it is often helpful to turn your phone sideways before adding your signature.</b> : ''}</p>
                    ) : (
                        <div>
                            <p className="text-sm">Let's get started adding your signature. Don't worry adding it here will not sign the document and you can change it at any time.</p>
                            <p className="text-sm mb-0">Simply use your finger (mobile) or click and drag your mouse in the box below.</p>
                        </div>
                    )}

                </div>
                
                <div className="modal-body bg-secondary signature-body">
                    <FormGroup>
                        <div className="signature-box" id="signBox">
                            <SignatureCanvas
                                width={width}
                                height={height}
                                clearOnResize={false}
                                backgroundColor="rgba(255,255,255,.0)"
                                onEnd={() => this.setState({signatureErrors: false})}
                                penColor='#718fba'
                                throttle={4}
                                maxWidth={2}
                                minDistance={3}
                                canvasProps={{className: 'sigCanvas' }}
                                ref={(ref) => { this.sigPadSignature = ref }}
                            />
                        </div>
                    </FormGroup>
                </div>

                <div className="modal-footer">
                    <button onClick={this.clearSigPad} className="btn btn-outline-danger" >Clear Signature</button>
                    <button onClick={this.onConfirm} className="btn btn-success" >Next</button>
                </div>

            </Modal>

        );
    }
}

SignModalAddSignature.propTypes = {
    showModalAddSignature   : PropTypes.bool.isRequired,
    toggleModalAddSignature : PropTypes.func.isRequired,
    setSignature            : PropTypes.func.isRequired,
    signature               : PropTypes.string,
}

export default SignModalAddSignature
