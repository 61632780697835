
import React from "react";
import { Col, Row } from 'reactstrap';

import ModalToggler from 'components/functional/modals/Toggler'

import ModalConfirm from '../Modals/Confirm'

const SignPreviewProgress = ({loaded, onSign, signingDocument}) => !loaded ? <></> : (
    <div className="doc-progress bg-white border-top z-depth-5">
        <Row>
            <div className="align-self-center col-auto" style={{width: 'calc(100% - 150px)'}}>
                You Are Ready To Sign!
            </div>

            <Col className="align-self-center text-right col-auto">
                <span className="position-relative d-inline-block">
                    <i className="fas fa-arrow-down confirmation-arrow " />
                    <ModalToggler signingDocument={signingDocument} component={ModalConfirm} onSign={onSign}>
                        <button className="btn btn-success">Adopt & Sign </button>
                    </ModalToggler>
                </span>
            </Col>
        </Row>
    </div>
)

export default SignPreviewProgress