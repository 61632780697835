import { useState, useCallback } from 'react';
import { Modal, FormGroup } from 'reactstrap';

import ModalToggler from 'components/functional/modals/Toggler'

import ModalDisclosures from '../Disclosures'

import _signing_events from '_functions/signing_events'

const SignModalConfirm = ({showModal, toggleModal, onSign, signingDocument}) => {

    const [state, setState] = useState({
        confirmed1: false,
        confirmed2: false,
        confirmed3: false,
        needConfirmed1: false,
        needConfirmed2: false,
        needConfirmed3: false,
    })

    const { confirmed1, confirmed2, confirmed3, needConfirmed1, needConfirmed2, needConfirmed3 } = state;

    const onSetState = useCallback((values) => {
        let _state = JSON.parse(JSON.stringify(state));
        _state = {..._state, ...values}
        setState(_state)

        console.log(_state)
        if(_state.confirmed1 && _state.confirmed2 && _state.confirmed3) {
            _signing_events.create({
                contact             : signingDocument.contactParty.contact, 
                signing_document    : signingDocument._id,
                event               : 'Accepted All Terms'
            })
        }
    }, [state, signingDocument])

    const onConfirmSign = useCallback(() => {
        const { confirmed1, confirmed2, confirmed3 } = state;

        onSetState({
            needConfirmed1: !confirmed1,
            needConfirmed2: !confirmed2,
            needConfirmed3: !confirmed3,
        })

        if(confirmed1 && confirmed2 && confirmed3) onSign()
    }, [state, onSign, onSetState])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Finish And Sign</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <p className="text-sm">You are just about done. Confirm the fields below and click "Finish" to sign your document.</p>

                    <FormGroup>
                        <div className={`custom-control custom-checkbox ${needConfirmed1 ? 'checkboxErr' : ''}`}>
                            <input
                                className="custom-control-input"
                                type="checkbox"
                                id="confirmed1"
                                value={confirmed1 || ''}
                                checked={confirmed1}
                                onChange={(e) => onSetState({confirmed1: !confirmed1})}
                            />
                            <label className="custom-control-label" htmlFor="confirmed1">
                                I have confirmed the above document is correctly signed and filled out and I understand that I have other options to sign this document besides electronic signature
                            </label>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <div className={`custom-control custom-checkbox ${needConfirmed2 ? 'checkboxErr' : ''}`}>
                            <input
                                className="custom-control-input"
                                type="checkbox"
                                id="confirmed2"
                                value={confirmed2 || ''}
                                checked={confirmed2}
                                onChange={(e) => onSetState({confirmed2: !confirmed2})}
                            />
                            <label className="custom-control-label" htmlFor="confirmed2">
                                I have confirmed that no part of my signature or initials are cut off and that I give consent for this to be a legally binding document the same way it would be if signed on paper.
                            </label>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <div className={`custom-control custom-checkbox ${needConfirmed3 ? 'checkboxErr' : ''}`}>
                            <input
                                className="custom-control-input"
                                type="checkbox"
                                id="confirmed3"
                                value={confirmed3 || ''}
                                checked={confirmed3}
                                onChange={(e) => onSetState({confirmed3: !confirmed3})}
                            />
                            <label className="custom-control-label" htmlFor="confirmed3">
                                I have read and accept the Consumer Disclosures regarding E-Signing documents.
                                
                                <span 
                                    onClick={(e) => e.preventDefault() } 
                                    className="text-underline text-dark font-weight-bold"
                                    style={{position: 'relative', top: -4, left: 3}}
                                >
                                    <ModalToggler component={ModalDisclosures}>Click Here To View</ModalToggler>
                                </span>
                            </label>
                        </div>
                    </FormGroup>

                </div>

                {needConfirmed1 || needConfirmed2 || needConfirmed3 ? (
                    <div className="modal-body bg-secondary text-center">
                        <p className="text-warning font-weight-bold mb-0">Please check the boxes above before continuing.</p>
                    </div>
                ) : null}
                

                <div className="modal-footer">
                    <button onClick={toggleModal} className="btn btn-outline-warning">Cancel</button>
                    <button onClick={onConfirmSign} className="btn btn-outline-success">Finish!</button>
                </div>
            </div>

        </Modal>
    )
}

export default SignModalConfirm
