/*
Documentation

this page handles log in for a user
on successful login we set a cookie architeck_uid
when we send off to see if the user is logged in this is
passed along as well to prevent cors cookie issues

*/

import keys from 'keys';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { Col, Container, Row, Card, CardBody } from "reactstrap";

import Home from './images/Home2.svg'

const Login = ({match}) => {

    useEffect(() => {



    }, [])


    return (
        <>

			<Helmet>
				<title>Zap Reports</title>
				<meta name="description" content="Zap Reports" />
			</Helmet>


			<Container className="my-4 my-lg py-lg-9 p-0">
                <Row>
                    <Col lg={5} className="align-self-center">
                       <Card className="z-depth-4">
                           <CardBody className="bg-dark">
                           <img style={{width: 300}} alt="..."  src={keys.LOGO} />
                            <p className="text-white font-weight-bold mt-2" >Documents In Seconds</p>

                            <hr style={{borderBottom: 'solid 1px white'}}/>

                            <p className="text-white" >Signing your document is right around the corner. If you're on this page it means your just a few clicks away from e-signing a document sent to you.</p>
                            <p className="text-white font-weight-bold" >To get started, please click the link that was either emailed to you or texted to your phone.</p>
                            
                            <hr style={{borderBottom: 'solid 1px white'}}/>

                            <p className="text-white font-weight-bold" ><i className="fas fa-lock mr-3 " /> 
                                <span className="text-underline">
                                Your information is safe and secure.
                                </span>
                                
                            </p>
                           </CardBody>
                       </Card>
                    </Col>
                    <Col lg={1} className="align-self-center"/>
                    <Col lg={6} className="align-self-center">
                        <img src={Home} className="w-100" alt="..." />
                    </Col>
                </Row>
			</Container>
		</>

    )
}

export default Login

